<template>
  <div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
    >
      <v-icon>{{ snackbar.icon }}</v-icon>
      <span class="white--text ml-3">{{ snackbar.text }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Fechar
        </v-btn>
        <v-btn
          v-if="snackbar.confirmationBtn"
          color="secondary"
          text
          @click="snackbar.confirm()"
        >
          Confirmar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="800">
      <PropertiesForm
        :property="property"
        :isNew="isNew"
        @close="dialog = false"
        @update="updateProperty"
        @create="createProperty"
      />
    </v-dialog>
    <v-dialog v-model="imgDialog" persistent max-width="1200">
      <UploadImages
        @close="imgDialog = false"
        @upload="uploadImages"
        @setHighlighted="setHighlighted"
        @delete="deleteImage"
        :isLoading="isLoading"
        :images="images"
      />
    </v-dialog>
    <Table
      :data="data.items ? data : getProperties()"
      @edit="editProperty"
      @delete="deleteProperty"
      @new="newProperty"
      @addImages="addImages"
      @viewPublishedProperty="viewPublishedProperty"
    />
  </div>
</template>

<script>
export default {
  components: {
    Table: () => import("@/components/data-table/Table"),
    PropertiesForm: () => import("./PropertiesForm.vue"),
    UploadImages: () => import("./UploadImages.vue"),
  },
  data: () => ({
    dialog: false,
    images: [],
    imgDialog: false,
    isLoading: false,
    isNew: false,
    property: {
      owner_id: null,
      broker_id: null,
      city_id: null,
      property_category_id: null,
      property_type_id: null,
      form_payment_id: null,
      property_status_id: null,
      property_tag_id: null,
      title: null,
      value: null,
      cep: null,
      address: null,
      number: null,
      district: null,
      complement: null,
      short_description: null,
      description: null,
      views: 0,
      bedroom: null,
      bathroom: null,
      garage: null,
    },
    data: {
      items: [],
      isLoading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Título", value: "title" },
        { text: "Status", value: "property_status", align: "center" },
        // { text: "Corretor", value: "broker", align: "center" },
        // { text: "Proprietário", value: "owner", align: "center" },
        { text: "Categoria", value: "property_category", align: "center" },
        { text: "Tipo", value: "property_type", align: "center" },
        { text: "Cidade", value: "city", align: "center" },
        { text: "Ações", value: "actions", align: "center" },
      ],
    },
    snackbar: {
      color: "success",
      show: false,
      icon: "mdi-check-circle",
      confirmationBtn: false,
      confirm: Function,
      negate: Function,
      text: "",
      timeout: 2000,
    },
    currentPropertyId: null,
  }),
  methods: {
    /*
     ** Métodos http
     */
    getProperties() {
      this.$api
        .get("properties")
        .then((res) => {
          console.log(res.data.data);
          this.data.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.data.isLoading = false;
        });
    },
    createProperty() {
      this.property.form_payment_id = this.property.form_payment_id
        ? this.property.form_payment_id.id
        : null;
      this.property.property_category_id = this.property.property_category_id
        ? this.property.property_category_id.id
        : null;
      this.property.city_id = this.property.city_id
        ? this.property.city_id.id
        : null;
      this.property.property_tag_id = this.property.property_tag_id
        ? this.property.property_tag_id.id
        : null;
      this.property.property_type_id = this.property.property_type_id
        ? this.property.property_type_id.id
        : null;
      this.$api
        .post("properties", this.property)
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Imóvel cadastrado com sucesso",
            icon: "mdi-check-circle",
          };
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getProperties();
        });
    },
    deleteProperty(item) {
      this.snackbar = {
        show: true,
        color: "warning",
        text: "Deseja excluir este imóvel?",
        icon: "mdi-alert",
        confirmationBtn: true,
        timeout: -1,
        confirm: () => {
          this.$api
            .delete(`properties/${item.id}`)
            .then(() => {
              this.snackbar = {
                show: true,
                color: "success",
                text: "Imóvel excluído com sucesso",
                icon: "mdi-check-circle",
                timeout: 2000,
              };
              this.getProperties();
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                color: "error",
                text: "Ops! Ocorreu um erro",
                icon: "mdi-alert-octagon",
                timeout: 2000,
              };
            });
        },
        negate: () => {
          this.snackbar = {};
        },
      };
    },
    updateProperty() {
      this.property.form_payment_id = this.property.form_payment_id
        ? this.property.form_payment_id.id
        : null;
      this.property.property_category_id = this.property.property_category_id
        ? this.property.property_category_id.id
        : null;
      this.property.city_id = this.property.city_id
        ? this.property.city_id.id
        : null;
      this.property.property_tag_id = this.property.property_tag_id
        ? this.property.property_tag_id.id
        : null;
      this.property.property_type_id = this.property.property_type_id
        ? this.property.property_type_id.id
        : null;

      delete this.property.images;

      this.$api
        .patch(`properties/${this.property.id}`, this.property)
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Imóvel atualizado com sucesso",
            icon: "mdi-check-circle",
          };
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getProperties();
        });
    },
    setHighlighted(item) {
      this.$api
        .patch(`/uploads/property/image_highlighted/${item.id}`)
        .then(() => {
          this.getImages();
        });
    },
    deleteImage(item) {
      this.$api.delete(`/uploads/property/images/${item.filename}`).then(() => {
        this.getImages();
      });
    },
    addImages(item) {
      this.currentPropertyId = item.id;
      this.imgDialog = true;
      this.getImages();
    },
    viewPublishedProperty(item) {
      window.location = "http://localhost:8081/#/property/" + item.id;
    },
    getImages() {
      this.$api
        .get(`/uploads/property/images/${this.currentPropertyId}`)
        .then((res) => {
          this.images = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async uploadImages(files) {
      this.isLoading = true;

      for (let file of files) {
        let formData = new FormData();
        formData.append("files[]", file, file.name);

        await this.$api
          .post(`/uploads/property/images/${this.currentPropertyId}`, formData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        this.getImages();
      }
      this.isLoading = false;
    },

    /*
     ** Ouvindo eventos da tabela (novo, editar, excluir)
     */
    newProperty() {
      this.dialog = true;
      this.isNew = true;
      this.property = {
        title: null,
        value: null,
        cep: null,
        address: null,
        number: null,
        district: null,
        complement: null,
        short_description: null,
        description: null,
        views: 0,
        bedroom: null,
        bathroom: null,
        garage: null,
      };
    },
    editProperty(item) {
      this.dialog = true;
      this.isNew = false;
      this.$api
        .get(`properties/${item.id}`)
        .then((res) => {
          this.property = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getProperties();
  },
};
</script>